import { Link } from 'gatsby'
import * as React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/Layout'
import { ContentSection } from '../components/content/ContentSection'
import { LeadershipSection } from '../components/LeadershipSection'
import { BoardSection } from '../components/BoardSection'
import * as craft from '../images/about/sg-craftventures.png'
import * as felicis from '../images/about/sg-felicis.png'
import * as goldcrest from '../images/about/sg-goldcrest.png'
import * as redpoint from '../images/about/sg-redpoint.png'
import * as sequoia from '../images/about/sg-sequoia.png'
import * as andreessenhorowitz from '../images/about/sg-andreessen-horowitz.png'

export default class About extends React.Component<any, any> {
    public render(): JSX.Element | null {
        return (
            <Layout location={this.props.location}>
                <div className="about content-page bg-white text-dark">
                    <Helmet>
                        <title>关于我们</title>
                        <meta name="twitter:title" content="关于我们" />
                        <meta property="og:title" content="关于我们" />
                        <meta name="twitter:site" content="@codein" />
                        <meta name="twitter:image" content="https://home.codein.dev/favicon.png" />
                        <meta name="twitter:card" content="概况" />
                        <meta
                            name="twitter:description"
                            content="CodeIN是智能代码搜索引擎"
                        />
                        <meta
                            property="og:description"
                            content="CodeIN是智能代码搜索引擎"
                        />
                        <meta
                            name="description"
                            content="CodeIN是智能代码搜索引擎"
                        />
                    </Helmet>
                    <div>
                        <div className="content-section hero-section py-5">
                            <div className="container py-5">
                                <h1>公司介绍</h1>
                                <p className="h4 font-weight-normal">
                                北京码搜智能科技有限公司 (CodeIN) 成立于 2022 年，是国内领先的代码搜索平台。<br />
                                CodeIN智能代码搜索平台帮助提高代码质量，分析代码关系，提升团队工作效率，保障产品交付质量，帮助企业更好更快发布产品。
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </Layout>
        )
    }
}
